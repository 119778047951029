
import { defineComponent } from 'vue'
import alertBox from '@/components/alert-box.vue'
export default defineComponent({
    name: 'InsuranceAddSubtractAdd',
    components: {
        'alert-box': alertBox
    },
    props: ['msg'],
    data () {
        return {}
    },
    methods: {
        // 取消
        close () {
            this.$emit('close')
        },
        submit () {
            this.$emit('submit')
        }
    }
})
