
import { defineComponent } from 'vue'
import alertBox from '@/components/alert-box.vue'
export default defineComponent({
    name: 'InsuranceAddSubtractAdd',
    components: {
        'alert-box': alertBox
    },
    props: ['name', 'type', 'count', 'date', 'money', 'addCount', 'isMonth'],
    data () {
        return {
            disabled: false
        }
    },
    created () {
        this.disabled = false
    },
    methods: {
        // 取消
        cancel () {
            this.$emit('cancel')
        },
        submit () {
            this.disabled = true
            this.$emit('submit')
        }
    }
})
