import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7261cc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-head" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.titles, (item, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index,
        class: _normalizeClass({
                'router-first': _ctx.$store.state.titles.length === 1,
                'router-last': _ctx.$store.state.titles.length > 1 && index != 0,
                router:
                    _ctx.$store.state.titles.length > 1 &&
                    index < _ctx.$store.state.titles.length - 1
            }),
        onClick: ($event: any) => (_ctx.goNav(index))
      }, _toDisplayString(item), 11, _hoisted_2))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _renderSlot(_ctx.$slots, "left", {}, undefined, true)
      ]),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "right", {}, undefined, true)
      ])
    ])
  ]))
}