import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ba69b67"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "alert-bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_box = _resolveComponent("alert-box")!

  return (_openBlock(), _createBlock(_component_alert_box, {
    class: "alert-form",
    width: "800px",
    dataTitle: "操作提示",
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.msg), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "mini-btn blue",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
        }, "确定")
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}