import { QueryDateSlotDto, QueryPageDto, ClientUserDto, WorkerDto } from './dto'
import request from './request'

// 获取保险方案列表
export interface InsuranceOpenDto {
  policy_id: number
  plan_id: number
  start_date: string
  end_date: string | null
  can_today: number
  active_person_count: number
  template: 'normal' | 'warning' | 'disable'
  mark: string
  plan: {
    plan_id: number
    logo_image_id: number
    type: string
    name: string
    color: string
    logo: string
  }
}

export function getInsuranceOpenData (): Promise<InsuranceOpenDto[]> {
    return request.get('/policy/list')
}

// 保费趋势
export function getInsuranceTrend (): Promise<{
  month: string[]
  premium: string[]
}> {
    return request.get('/statistics.data/monthPremium')
}

// 获取加减保记录
interface GetInsuranceRecordDto extends QueryPageDto, QueryDateSlotDto {
  policyId: string
  type?: string
}

export interface InsuranceRecordDto {
  clientUser: ClientUserDto
  client_user_id: number
  create_time: string
  end_date: string
  order_id: number
  pay_money: string
  pay_status: number
  pay_type: number
  policy: {
    policy_id: number
    plan_id: number
    start_date: string
    end_date: string
    can_today: number
  }
  policy_id: number
  source: string
  start_date: string
  status: string
  type: string
}

export function getInsuranceRecord (
    params: GetInsuranceRecordDto
): Promise<{ data: InsuranceRecordDto[]; total: number }> {
    return request.get('/order/list', {
        params
    })
}

// 保单详情
export interface InsuranceDetailDto {
  active_person_count: number
  can_today: number
  is_close: number
  can_renew: boolean
  currentMonthPremium: number
  end_date: string
  person_count: number
  plan: {
    plan_id: number
    logo_image_id: number
    type: string
    name: string
    color: string
    remark: string
  }
  plan_id: number
  policy_id: number
  start_date: string
  replace_info?: {
    level3: 0
    level4: 0
    level5: 0
  }
}

export function getInsuranceDetail (params: {
  policyId: number
}): Promise<InsuranceDetailDto> {
    return request.get('/policy/detail', {
        params
    })
}

// 被派遣单位数据统计
export function getCompanyPieStatistics (params: {
  policyId?: string
  onlyActive: string
}): Promise<{ name: string; count: number }[]> {
    return request.get('/statistics.data/companyPie', {
        params
    })
}

// 被派遣单位数据统计
export function getOccupationPieStatistics (params: {
  policyId?: string
  onlyActive: string
}): Promise<{ name: string; count: number }[]> {
    return request.get('/statistics.data/occupationPie', {
        params
    })
}

// 可开通的方案列表
export interface PlanDto {
  can_today: number
  color: string
  desc: string
  feature: { title: string; desc: string }[]
  is_hot: 0 | 1
  logo: string
  name: string
  remark: string
  plan_id: string
  price: string
  price4: string
  type: string
}

export function getPlanList (params: { type: string, supplier: string }): Promise<PlanDto[]> {
    return request.get('/plan/list', {
        params
    })
}

// 开通新方案
export function openPlan (data: { planId: string }): Promise<void> {
    return request.post('/policy/add', data)
}

// 加减保员工数据
export interface InsuranceAddWorkerDto {
  name: string
  idcard: string
  company_name?: string
  company_id?: number | null
  occupation_name?: string
  occupation_id?: number | null
}

interface InsuranceAddSubtractReqDto {
  policyId: number
  startDate: string
  persons: InsuranceAddWorkerDto[]
}

// 减保
export function InsuranceSubtract (
    data: InsuranceAddSubtractReqDto
): Promise<{
  errorMsg:string
}> {
    return request.post('/order/off', data)
}

// 加保
export function InsuranceAdd (data: InsuranceAddSubtractReqDto): Promise<{
  orderId:number|string
  errorMsg:string
}> {
    return request.post('/order/add', data)
}

// 加保金额获取
export function InsuranceAddMonth (
    data: InsuranceAddSubtractReqDto
): Promise<{
  pay_money: number
  pay_count: number
  orderId:number|string
  errorMsg:string
}> {
    return request.post('/order/preview', data)
}

export interface RenewDataDto {
  startDate: string
  endDate: string
  payMoney: string
  payCount: number
  personList: {
    employee: {
      name: string
      idcard: string
    }
    occupation_name: string
    occupation_level: string
    company_name: string
  }[]
}

export function renewPreview (params: {
  policyId: number
}): Promise<RenewDataDto> {
    return request.get('/policy/renewPreview', {
        params
    })
}

export function renewPolicy (data: { policyId: number }): Promise<void> {
    return request.post('/policy/renew', data)
}

// 风险等级数据统计
export function getRiskPieStatistics (params: {
  policyId?: string
  onlyActive: string
}): Promise<{ name: string; count: number }[]> {
    return request.get('/statistics.data/occupationLevelPie', {
        params
    })
}
